/* You can add global styles to this file, and also import other style files */
@import "~@angular/material/prebuilt-themes/indigo-pink.css";
@import '~@angular/material/theming';
html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

$mat-myapp-primary: (
    50 : #e0eefc,
    100 : #b3d4f7,
    200 : #80b8f2,
    300 : #4d9ced,
    400 : #2686e9,
    500 : #0071e5,
    600 : #0069e2,
    700 : #005ede,
    800 : #0054da,
    900 : #0042d3,
    A100 : #fbfcff,
    A200 : #c8d5ff,
    A400 : #95afff,
    A700 : #7b9bff,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

$mat-myapp-accent: (
    50 : #fffaed,
    100 : #fff4d1,
    200 : #ffecb3,
    300 : #ffe494,
    400 : #ffdf7d,
    500 : #ffd966,
    600 : #ffd55e,
    700 : #ffcf53,
    800 : #ffca49,
    900 : #ffc038,
    A100 : #ffffff,
    A200 : #ffffff,
    A400 : #fff5e1,
    A700 : #ffedc8,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #000000,
        600 : #000000,
        700 : #000000,
        800 : #000000,
        900 : #000000,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

$primary: mat-palette($mat-myapp-primary);
$accent: mat-palette($mat-myapp-accent);

$theme: mat-light-theme($primary, $accent);

@include angular-material-theme($theme);